/* Common styles and tags */
:root {
  --color-main: #825e5d;
  --color-grey: #a7a8aa;
  --color-lightgrey: #f2f5f7;
  --color-black: #000000;
  --color-input-border: #eeeeee;
  --color-grey-border: #eeeeee;
  --color-brand-red: #d62f66;
  --color-brand-green: #58959a;
  --color-brand-beige: #fbf5f2;

  --sidebar-width: 360px;

  --border-radius-input: 5px;
  --border-radius-small: 5px;
  --border-radius-regular: 10px;

  --fontsize-small: 0.9rem;

  --image-overlay-opacity: 0.4;
  --image-overlay-hover-opacity: 0.3;

  --icon-fontsize-large: 1.8rem;

  --box-shadow-large: 0 18px 31px rgba(0, 0, 0, 0.05);
  --box-shadow-regular: 0 0 1px rgba(0, 0, 0, 0.05), 0 0 3px rgba(0, 0, 0, 0.05),
    0 2px 8px rgba(0, 0, 0, 0.05);

  /* Device safe area */
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom); /* THIS ONE GETS US THE HOME BAR HEIGHT */
  --sal: env(safe-area-inset-left);
}
* {
  box-sizing: border-box;
  transition: background-color 200ms ease, color 200ms ease,
    border-color 200ms ease, margin 200ms ease;
}
.button,
button,
svg {
  transition: opacity 200ms ease;
}
body {
  padding: 0;
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  margin-bottom: calc(60px + var(--sab) * 0.8);
  font-weight: 400;
}
body.submenu-open {
  margin-bottom: calc(140px + var(--sab) * 0.8);
}
input,
select,
button,
textarea {
  font-family: 'Ubuntu', sans-serif;
}
input:disabled,
button:disabled,
input:disabled:hover,
button:disabled:hover {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: var(--color-grey);
  border-color: var(--color-grey);
}
input:not([type='checkbox']):not([type='radio']) {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.text-success {
  color: var(--color-brand-green);
}
.text-error {
  color: var(--color-brand-red);
}
.display {
  display: block;
}
.hide {
  display: none;
}
.text-highlight {
  border: 2px dashed var(--color-brand-green);
  padding: 15px;
  border-radius: var(--border-radius-regular);
  background-color: #cae3e5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Oswald', sans-serif;
  color: var(--color-main);
}
a,
.link {
  color: var(--color-main);
  text-decoration: none;
  cursor: pointer;
}
a:hover,
.link:hover {
  color: var(--color-black);
}
.margin-top-small {
  margin-top: 5px;
}
form {
  margin: 0;
  padding: 0;
}
.form-input-wrapper {
  margin-bottom: 15px;
}
input,
select,
button,
.button {
  width: 100%;
  border: 2px solid var(--color-input-border);
  padding: 10px 10px;
  border-radius: var(--border-radius-input);
  font-size: 16px;
  font-weight: 400;
}
.button {
  display: inline-block;
}
.button.alt {
  background-color: var(--color-black);
  border-color: var(--color-black);
}
.button.alt:hover {
  opacity: 0.8;
}
button.button-fixed,
.button.button-fixed {
  width: auto;
  padding-left: 30px;
  padding-right: 30px;
}
@media (max-width: 580px) {
  button.button-fixed,
  .button.button-fixed {
    width: 100%;
  }
}
button.button-with-icon {
  padding-left: 15px;
  padding-right: 20px;
}
button.button-destroy,
a.button-destroy {
  background-color: transparent;
  border-color: var(--color-brand-red);
  color: var(--color-brand-red);
}
button.button-destroy:hover,
a.button-destroy:hover {
  color: var(--color-brand-red);
}
.button.button-bordered {
  background-color: transparent;
  border-color: var(--color-main);
  color: var(--color-main);
}
.button.button-bordered:hover {
  color: var(--color-black);
  border-color: var(--color-black);
}
.button svg {
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.button-small {
  padding: 5px 15px !important;
}
.button-with-icon {
  display: flex;
  align-items: center;
}
.button-remove {
  font-size: 2rem;
  color: var(--color-brand-red);
  cursor: pointer;
  margin-left: 10px;
}
.button-remove:hover {
  color: var(--color-black);
}
.button-medium {
  width: 140px;
}
.margin-bottom {
  margin-bottom: 15px;
}
.flex {
  display: flex;
}
.gap {
  gap: 5px;
}
.margin-top {
  margin-top: 15px;
}
input:active,
input:focus {
  border-color: var(--color-main);
}
label {
  display: block;
  font-weight: bold;
  margin-bottom: 7px;
}
.form-description {
  font-size: 0.9rem;
  color: var(--color-grey);
  margin-top: 3px;
  display: block;
}
input[type='submit'],
input[type='button'],
button,
.button {
  background-color: var(--color-main);
  border-color: var(--color-main);
  color: #ffffff;
  width: 100%;
  cursor: pointer;
  text-align: center;
}
input[type='submit']:hover,
input[type='button']:hover,
button:hover,
.button:hover {
  opacity: 0.8;
  color: #ffffff;
}
.button-accent {
  background-color: var(--color-brand-green);
  border-color: var(--color-brand-green);
}
.button-cancel {
  background-color: transparent;
  color: var(--color-grey);
  border-color: var(--color-grey);
}
.button-cancel:hover,
button.button-destroy:hover {
  color: var(--color-main);
  border-color: var(--color-main);
}
.buttons-wrapper {
  display: flex;
  gap: 15px;
  margin-bottom: 30px;
}
.buttons-wrapper:not(:has(:not(:empty))) {
  margin-bottom: 0;
}
@media (max-width: 568px) {
  .buttons-wrapper {
    flex-wrap: wrap;
  }
}
/* Remove outline for non-keyboard :focus */
*:focus:not(.focus-visible) {
  outline: none;
}

::placeholder {
  opacity: 0.3;
}

/* Common wrappers */
.small-width-wrapper {
  width: 400px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .small-width-wrapper {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.page-content {
  padding: 15px 15px;
}
.page-content .page-title-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.page-content .page-title-wrapper button {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}
.page-content .page-title-wrapper button svg {
  font-size: 1.4rem;
}

@media (max-width: 580px) {
  .page-content .page-title-wrapper {
    display: block;
  }
}
.page-content .page-title {
  margin-top: 0;
  margin-bottom: 0;
  flex-grow: 1;
}
@media (max-width: 580px) {
  .page-content .page-title {
    margin-bottom: 15px;
  }
  .page-title-wrapper button {
    width: 100%;
  }
}
.page-content .page-info {
  color: var(--color-grey);
  text-align: left;
}
.page-content .page-info .page-info-icon {
  font-size: 5rem;
  text-align: center;
}
.page-content .refresh-button {
  color: var(--color-grey);
  display: flex;
  margin-left: 5px;
  cursor: pointer;
  border: 2px solid var(--color-grey-border);
  border-radius: var(--border-radius-small);
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
}
.page-content .refresh-button:hover {
  border-color: var(--color-main);
}
.page-content .refresh-button svg {
  font-size: 1.3rem;
}
.page-content .refresh-button:hover {
  color: var(--color-main);
}

.demo-mode {
  color: red;
}
/*
* Sidebar
*/
.col-2col {
  display: flex;
}
.col-2col .col-left {
  width: var(--sidebar-width);
  background-color: #eeeeee;
  position: fixed;
  height: 100%;
  background-position: center center;
  background-size: cover;
}
.col-2col .col-right {
  flex-grow: 1;
  margin-left: var(--sidebar-width);
}
@media (max-width: 767px) {
  .col-2col .col-left {
    display: none;
  }
  .col-2col .col-right {
    margin-left: 0;
  }
}

/*
* Logo
*/
.main-logo {
  color: var(--color-black);
  width: 220px;
  align-self: center;
}
.main-logo img {
  width: 100%;
  height: auto;
}

/*
* Message
*/
.message-wrapper {
  position: relative;
}
.message {
  padding: 10px 20px;
  background-color: #eeeeee;
  color: var(#fff);
  margin-bottom: 20px;
  border-radius: var(--border-radius-small);
}
.message.success {
  background-color: rgb(237, 247, 237);
  color: rgb(30, 70, 32);
}
.message.error {
  background-color: rgb(253, 237, 237);
  color: rgb(95, 33, 32);
}
.message.notice {
  background-color: rgb(229, 246, 253);
  color: rgb(1, 67, 97);
}
.message a {
  color: var(--color-black);
}
.message a:hover {
  opacity: 0.7;
}

/* 
* Message fullwidth
*/
.message-fullwidth {
  border-radius: 0;
  padding: 20px 15px;
  margin-bottom: 0;
  text-align: center;
  position: relative;
}
.message-fullwidth svg {
  margin-right: 10px;
}
.message-fullwidth p {
  margin-top: 0;
  margin-bottom: 5px;
}
.message-fullwidth p:last-child {
  margin-bottom: 0;
}
.message-fullwidth a {
  color: red;
}
.message-fullwidth.dismissable {
  padding-right: 40px;
}

.message-close-button {
  position: absolute;
  font-size: 1rem;
  color: var(--color-main);
  margin: 0;
  margin-bottom: 20px;
  cursor: pointer;
  right: 10px;
  top: 10px;
}
.message-close-button:hover {
  color: var(--color-main);
}
/*
* User Header
*/
.user-header {
  width: 100%;
  display: flex;
  color: var(--color-black);
  background: #ffffff;
  border-bottom: 1px solid var(--color-input-border);
  padding: 10px 15px;
}
.user-header .title {
  flex-grow: 1;
  text-align: left;
  display: flex;
  align-items: center;
}
.user-header .logout-button {
  color: var(--color-main);
  cursor: pointer;
}
.user-header .logout-button:hover,
.user-header .logout-button a:hover {
  color: var(--color-black);
}
.user-header .go-back-button {
  color: var(--color-grey);
  display: flex;
  margin-left: 5px;
  cursor: pointer;
  border: 2px solid var(--color-grey-border);
  border-radius: var(--border-radius-small);
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-top: -3px;
}
.user-header .go-back-button:hover {
  color: var(--color-main);
  border-color: var(--color-main);
  cursor: pointer;
}
.user-header-nav {
  display: flex;
}
.user-header-nav a {
  margin-right: 25px;
}
.user-header-nav a,
.user-header-nav div {
  display: flex;
  align-items: center;
}
.user-header-nav svg {
  margin-right: 5px;
  color: var(--color-black);
  font-size: 18px;
}
.user-header-nav a:hover svg,
.user-header-nav div:hover svg {
  opacity: 0.6;
}
.user-header-nav a svg {
  font-size: 16px;
}
.user-header .main-logo {
  width: 130px;
}
@media (max-width: 580px) {
  .user-header-nav span {
    display: none;
  }
  .user-header-nav a {
    margin-right: 10px;
  }
  .user-header-nav a svg {
    font-size: 1.2rem;
  }
  .user-header-nav svg {
    margin-right: 0;
    font-size: 1.2rem;
  }
}
/*
* Auth Footer 
*/
.auth-details {
  text-align: center;
  font-size: var(--fontsize-small);
  color: var(--color-grey);
  padding-left: 10px;
  padding-right: 10px;
}
.auth-footer {
  margin-top: 30px;
  text-align: center;
  font-size: var(--fontsize-small);
  color: var(--color-grey);
}

/* Login menu component */
.login-menu {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  width: 100%;
}
.login-menu a {
  flex-grow: 1;
  text-align: center;
  width: 50%;
  padding: 10px 30px;
  border-bottom: 2px solid var(--color-grey-border);
}
.login-menu a.active {
  border-color: var(--color-main);
  color: #000000;
}

/*
* Recipe section
*/
.page-section {
  margin-bottom: 30px;
}
.page-section .section-title {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.page-section h2 {
  color: var(--color-black);
  flex-grow: 1;
  margin-top: 0;
  margin-bottom: 0;
}
.page-section .recipe-card {
  /* width: 300px; */
  height: 300px;
  flex-shrink: 0;
  position: relative;
  border-radius: var(--border-radius-regular);
  overflow: hidden;
}
@media (max-width: 768px) {
  .page-section .recipe-card {
    height: 180px;
  }
}
.page-section .recipe-card:hover .recipe-image-overlay {
  background-color: rgba(0, 0, 0, var(--image-overlay-hover-opacity));
}
.page-section .recipe-card:hover .recipe-inner {
  margin-top: -5px;
}
.page-section .recipe-image-overlay {
  background-color: rgba(0, 0, 0, var(--image-overlay-opacity));
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.page-section .recipe-image {
  background-position: center center;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.page-section .recipe-inner {
  padding: 30px;
  position: relative;
  z-index: 10;
  height: 100%;
}
.page-section .recipe-inner .recipe-icons {
  position: absolute;
  top: 30px;
  right: 30px;
  color: #ffffff;
  font-size: 23px;
}
@media (max-width: 768px) {
  .page-section .recipe-inner .recipe-icons {
    top: 20px;
  }
}
.page-section .recipe-card.recipe-card-line .recipe-inner .recipe-icons {
  top: -10px;
  right: 0;
  left: auto;
  opacity: 0.8;
  color: var(--color-black);
  opacity: 0.5;
}
@media (max-width: 768px) {
  .page-section .recipe-inner {
    padding: 20px;
  }
  .page-section .recipe-card.recipe-card-line .recipe-inner .recipe-icons {
    top: 0;
  }
}
.page-section .recipe-title {
  color: #ffffff;
  font-size: 1.4rem;
  margin: 0;
}
@media (max-width: 768px) {
  .page-section .recipe-title {
    font-size: 1.1rem;
  }
}
.page-section .recipe-categories {
  display: flex;
  margin-bottom: 10px;
}
.page-section .recipe-categories .recipe-category {
  margin-right: 5px;
  color: #ffffff;
  background-color: var(--color-main);
  padding: 3px 7px;
  border-radius: var(--border-radius-small);
  font-size: var(--fontsize-small);
}
.page-section .recipe-time {
  font-size: 0.8rem;
  color: #ffffff;
  position: absolute;
  right: 0;
  bottom: -10px;
  display: flex;
  align-items: center;
  background-color: var(--color-main);
  padding: 10px 15px;
  padding-bottom: 20px;
  border-top-left-radius: var(--border-radius-regular);
}
.page-section .recipe-time svg {
  font-size: 1rem;
  margin-right: 5px;
}
.page-section .recipe-time svg:last-child {
  margin-left: 10px;
}
.page-section .slider-nav {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  justify-content: right;
}
.page-section .nav-button {
  border: 2px solid var(--color-grey-border);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: var(--border-radius-small);
  cursor: pointer;
  color: var(--color-grey);
  flex-shrink: 0;
}
.page-section .nav-button:hover {
  border-color: var(--color-main);
  color: var(--color-main);
}
.page-section .nav-button.nav-button-wide {
  width: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.page-section .recipe-card.recipe-card-line {
  width: 100%;
  height: auto;
  border-radius: 0;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--color-grey-border);
  padding-bottom: 15px;
  overflow: visible;
}
.page-section .recipe-card.recipe-card-line .recipe-time {
  background: none;
  padding: 0;
}
.page-section .recipe-card.recipe-card-line:hover .recipe-title {
  color: var(--color-black);
}
.page-section .recipe-card.recipe-card-line a {
  display: block;
  display: flex;
  align-items: center;
}
.page-section .recipe-card.recipe-card-line .recipe-image {
  border-radius: var(--border-radius-small);
  flex-shrink: 0;
}
.page-section .recipe-card.recipe-card-line .recipe-image-overlay {
  display: none;
}
.page-section .recipe-card.recipe-card-line .recipe-image {
  background-position: center center;
  background-size: cover;
  position: relative;
  width: 100px;
  height: 80px;
  margin-right: 15px;
}
.page-section .recipe-card.recipe-card-line .recipe-inner {
  padding: 0;
  position: relative;
  height: auto;
  flex-grow: 1;
}
.page-section .recipe-card.recipe-card-line .recipe-title {
  color: var(--color-black);
  font-size: 1.3rem;
  margin-bottom: 5px;
}
.page-section .recipe-card.recipe-card-line :hover .recipe-title {
  color: var(--color-main);
}
.page-section .recipe-card.recipe-card-line .recipe-categories {
  display: flex;
  margin-bottom: 5px;
}
.page-section
  .recipe-card.recipe-card-line
  .recipe-categories
  .recipe-category {
  margin-right: 5px;
  color: #ffffff;
  background-color: var(--color-main);
}
.page-section .recipe-card.recipe-card-line .recipe-time {
  color: var(--color-grey);
  position: relative;
  display: flex;
  align-items: center;
  right: auto;
  bottom: auto;
  font-size: 0.9rem;
}
.page-section .recipe-card.recipe-card-line .recipe-time svg {
  font-size: 0.9rem;
}
.page-section .recipe-card.recipe-card-line .recipe-time svg:first-child {
  margin-left: 0;
}
.page-section .recipe-add-to-fav {
  cursor: pointer;
  flex-shrink: 0;
}
.page-section .recipe-add-to-fav {
  color: var(--color-brand-red);
}
.page-section .recipe-add-to-fav:hover {
  color: var(--color-black);
}

/* Recipe video */
.recipe-video-page .recipe-video {
  aspect-ratio: 9 / 16;
  width: 100%;
  height: auto;
  border-radius: 15px;
}
.modal-dialog.recipe-video-modal {
  overflow: scroll;
  height: 80%;
}
@media (max-width: 767px) {
  .modal-dialog.recipe-video-modal {
    height: 90%;
  }
}

/*
* Search
*/
.search-box {
  position: relative;
  display: flex;
  align-items: center;
}
.search-box svg {
  font-size: 1.6rem;
}
.search-box input {
  width: 320px;
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.search-box input:active,
.search-box input:focus {
  border-color: var(--color-grey-border);
}
.search-box .button-clear {
  position: absolute;
  right: 50px;
  margin-top: 2px;
  color: var(--color-grey);
  background-color: #ffffff;
  padding-left: 5px;
  cursor: pointer;
}
.search-box .button-clear svg {
  font-size: 1.5rem;
}
.search-box .button-clear:hover {
  color: var(--color-main);
}
.search-box .button-search {
  height: 43px;
  width: 43px;
  border: 2px solid var(--color-grey-border);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-input);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  color: var(--color-grey);
  cursor: pointer;
  flex-shrink: 0;
}
.search-box .button-search:hover {
  color: var(--color-main);
  border-color: var(--color-main);
}
.search-box input {
  height: 43px;
}
@media (max-width: 580px) {
  .search-box {
    margin-top: 15px;
  }
  .search-box input {
    flex-grow: 1;
    width: auto;
  }
}
/* 
* Checkbox
 */
/* Radio buttons (custom) */
.checkbox {
  vertical-align: top;
  width: 17px;
  height: 17px;
  margin: 0 3px 0 0;
}
.checkbox + label {
  cursor: pointer;
  margin-right: 15px;
}
.checkbox:not(checked) {
  position: absolute;
  opacity: 0;
}
.checkbox:not(checked) + label {
  position: relative;
  padding: 0 0 0 35px;
  margin-bottom: 20px;
}
.checkbox:not(checked) + label:before {
  content: '';
  position: absolute;
  top: -4px;
  left: 0;
  width: 24px;
  height: 24px;
  border: 2px solid var(--color-grey-border);
  border-radius: 50%;
  background: #ffffff;
}
.checkbox:not(checked) + label:after {
  content: '';
  position: absolute;
  top: 6px;
  left: 0;
  width: 14px;
  height: 14px;
  background: var(--color-black);
  opacity: 0;
  transition: all 0.2s;
}
.checkbox.checkbox_squared:not(checked) + label:after {
  background: var(--color-main);
  top: 3px;
  left: 7px;
  border-radius: 2px !important;
}
.checkbox.checkbox_squared:not(checked)[disabled] + label::after {
  color: var(--color-grey);
}
.checkbox.checkbox_squared:not(checked)[disabled] + label {
  cursor: default;
}
.checkbox:checked + label:after {
  opacity: 1;
}
.checkbox.checkbox_squared:not(checked) + label:before,
.checkbox.checkbox_squared:not(checked) + label:after {
  border-radius: var(--border-radius-input);
}
/*
* Toastify
*/
body .Toastify__close-button {
  width: 20px;
}
body .Toastify__toast-container--bottom-right {
  bottom: 60px;
}
@media (max-width: 767px) {
  body .Toastify__toast-container--bottom-right {
    bottom: calc(80px + var(--sab));
  }
}

/*
* Swiper slider
*/
body .swiper-horizontal > .swiper-pagination-bullets,
body .swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 30px;
}
body .swiper-pagination-bullet-active {
  background-color: #ffffff;
}
body .swiper-pagination-bullet {
  width: 13px;
  height: 13px;
}
/* 
* Price table
*/
.price-tables-wrapper {
  justify-content: center;
  display: flex;
  gap: 30px;
  margin-bottom: 40px;
  margin-top: 30px;
  flex-wrap: wrap;
}
.price-table {
  border: 1px solid var(--color-grey-border);
  border-radius: var(--border-radius-regular);
  text-align: center;
  flex-grow: 1;
  box-shadow: var(--box-shadow-regular);
  max-width: 400px;
}
.price-table.price-table-highlight {
  border-color: var(--color-brand-green);
}
.price-table.price-table-highlight .price-table-title {
  background-color: var(--color-brand-green);
}
.price-table-title h3 {
  font-size: 1.4rem;
  color: var(--color-main);
  margin: 0;
  color: #ffffff;
}
.price-table-title {
  padding: 20px 20px;
  background-color: var(--color-main);
  overflow: hidden;
  border-radius: var(--border-radius-small);
  border-bottom: 1px solid var(--color-grey-border);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.price-table-cost {
  background-color: var(--color-lightgrey);
  font-size: 1.2rem;
  padding: 20px;
}
.price-table-cost s {
  color: var(--color-brand-red);
}
.price-table .discount {
  color: var(--color-brand-green);
  font-size: 0.9rem;
  margin-top: 5px;
}
.price-table-description {
  padding: 10px 20px;
}
.price-table-description ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.price-table-description li {
  border-bottom: 1px dashed var(--color-grey-border);
  padding: 7px 0;
}
.price-table-button {
  padding: 10px 20px;
  padding-bottom: 20px;
}
.price-table-button a {
  margin-top: 15px;
  display: block;
}
.price-table sup {
  color: yellow;
  position: absolute;
  font-size: 17px;
  margin-left: 10px;
}
/*
* Modal dialog
*/
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);
}
.modal-dialog {
  position: fixed;
  padding: 20px;
  padding-bottom: 10px;
  border-radius: var(--border-radius-regular);
  background-color: #fff;
  box-shadow: var(--box-shadow-large);
  width: 440px;
  border: 1px solid var(--color-grey-border);
  z-index: 50;
  transform: translateY(-50%) translateX(-50%);
  top: 50%;
  left: 50%;
  animation: appear-anim 200ms ease-out forwards;
}
.modal-dialog h3 {
  font-size: 1.5rem;
  color: var(--color-main);
  margin: 0;
  margin-bottom: 20px;
}
.modal-close-button {
  position: absolute;
  cursor: pointer;
  top: 15px;
  right: 15px;
}
.modal-close-button:hover {
  color: var(--color-main);
}

.modal-dialog .control {
  margin-bottom: 15px;
}
.modal-dialog .control label {
  display: block;
  font-weight: 500;
  margin-bottom: 10px;
}
.modal-dialog .control input,
.modal-dialog .control select,
.modal-dialog .control button {
  width: 100%;
}
@keyframes appear-anim {
  from {
    opacity: 0;
    transform: translateY(0%) translateX(-50%);
  }
  to {
    opacity: 1;
    transform: translateY(-50%) translateX(-50%);
  }
}
@media (max-width: 767px) {
  .modal-dialog {
    position: fixed;
    width: auto;
    transform: translateY(0) translateX(0);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: scroll;
    animation: none;
    border-radius: 0;
  }
  .modal-backdrop {
    display: none;
  }
}

/*
* Tab bar
*/
.tab-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-top: 0px solid var(--color-grey);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 12px 0px rgba(34, 60, 80, 0.2);
  z-index: 20;
}
.tab-bar .tab-bar-item {
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
  position: relative;
}
.tab-bar .tab-bar-item .invite a {
  color: var(--color-brand-green);
}
.tab-bar .tab-bar-item.tab-bar-item-parent {
  position: static;
}

.tab-bar .tab-bar-item-submenu {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 100%;
  background-color: #ffffff;
  width: 100%;
  left: 0;
  z-index: 100;
  box-shadow: 0px -10px 12px 0px rgba(34, 60, 80, 0.2);
}
.tab-bar .tab-bar-item-submenu a {
  border-bottom: 1px solid var(--color-grey-border);
}
.tab-bar .tab-bar-badge {
  position: absolute;
  display: flex;
  width: 24px;
  height: 24px;
  font-size: 12px;
  color: #ffffff;
  background-color: var(--color-main);
  justify-content: center;
  align-items: center;
  top: calc(50% - 12px);
  right: 10px;
  border-radius: 24px;
  line-height: 1;
}

.tab-bar .tab-bar-item:hover > a,
.tab-bar .tab-bar-item > a.active {
  color: var(--color-main);
}
.tab-bar .tab-bar-ite > m a.active {
  border-top-color: var(--color-main);
}
.tab-bar .tab-bar-item > a {
  display: block;
  padding: 15px 10px;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid var(--color-grey-border);
  border-top: 3px solid transparent;
  padding-bottom: calc(var(--sab) * 0.8 + 15px);
}
.tab-bar .tab-bar-item svg {
  font-size: 1.5rem;
  margin-right: 10px;
}
@media (max-width: 970px) {
  .tab-bar .tab-bar-item > a {
    flex-direction: column;
    flex-wrap: wrap;
    font-size: 0.8rem;
    text-align: center;
  }
  .tab-bar .tab-bar-item svg {
    margin-right: 0;
    margin-bottom: 5px;
  }
  .tab-bar .tab-bar-badge {
    top: 7px;
    right: calc(50% - 28px);
  }
}
@media (max-width: 580px) {
  .tab-bar .tab-bar-item a {
    font-size: 0.7rem;
  }
}
@media (max-width: 604px) {
  .tab-bar .tab-bar-item-submenu .tab-bar-item a {
    min-height: 90px;
  }
}
/*
****** PAGES ******
*/

/* 
* Login page styles 
*/
.page-login .col-2col .col-left {
  background-image: url('assets/sidebar-login.jpg');
}
.page-login .page-login-wrapper {
  margin-top: 50px;
}
.page-login .page-login-wrapper h1 {
  text-align: center;
  margin-bottom: 40px;
}
.page-login .page-login-wrapper .form-link-wrapper {
  margin-bottom: 10px;
}
.page-login .page-login-wrapper .demo-acc-text {
  text-align: center;
  color: var(--color-grey);
  font-size: var(--fontsize-small);
  margin-bottom: 15px;
}

/* 
* Forgot password styles 
*/
.page-forgot-password .page-login-wrapper {
  margin-top: 50px;
}
.page-forgot-password .page-login-wrapper h1 {
  text-align: center;
  margin-bottom: 40px;
}
.page-forgot-password .col-2col .col-left {
  background-image: url('assets/sidebar-forgot-password.jpg');
}

/* 
* Register page styles 
*/
.page-register .col-2col .col-left {
  background-image: url('assets/sidebar-register.jpg');
}
.page-register .page-register-wrapper {
  margin-top: 50px;
}
.page-register .page-register-wrapper h1 {
  text-align: center;
  margin-bottom: 40px;
}
.page-register .page-register-wrapper h1 span {
  display: block;
  color: #000000;
}
.page-register .page-register-wrapper sup {
  color: red;
}

/* 
* Page Recipes
*/
.page-recipes .page-title {
  display: flex;
}
.page-recipes .page-title span {
  margin-right: 10px;
}
@media (max-width: 580px) {
  .page-recipes .page-title span {
    flex-grow: 1;
  }
}

/* 
* Page Not Found 
*/
.page-not-found-wrapper {
  text-align: center;
  margin-top: 100px;
  display: flex;
  justify-content: center;
}
.page-not-found-wrapper h1 {
  margin-bottom: 40px;
}
.page-not-found-wrapper .button {
  margin-top: 30px;
}

/*
* Search
*/
.page-search .page-title {
  color: var(--color-black);
}

/*
* Subscribe 
*/
.page-subscribe .manual-payment-link {
  margin-top: 15px;
  line-height: 0.5rem;
}
.page-subscribe .promocode {
  width: 320px;
}
.page-subscribe .promocode-form-wrapper input {
  margin-right: 15px;
}
@media (max-width: 580px) {
  .page-subscribe .promocode {
    width: 100%;
  }
  .page-subscribe .promocode-form-wrapper input {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

/*
* Single recipe
*/
.recipe-single-wrapper h1 {
  margin-top: 0;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
@media (max-width: 580px) {
  .recipe-single-wrapper h1 {
    display: block;
  }
  .recipe-single-wrapper .title {
    margin-bottom: 15px;
  }
  .recipe-single-wrapper .recipe-add-to-fav {
    margin-left: 0 !important;
  }
}
.recipe-single-wrapper h3 {
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 15px;
}
.recipe-single-wrapper h4 {
  font-size: 1.2em;
  margin-top: 0;
  margin-bottom: 15px;
}
.recipe-single-wrapper .recipe-actions {
  display: flex;
  gap: 5px;
}
.recipe-single-wrapper .recipe-actions svg {
  font-size: 2.2rem;
}
.recipe-single-wrapper .recipe-single-title .title {
  flex-grow: 1;
  color: var(--color-black);
}
.recipe-single-wrapper .recipe-categories {
  display: flex;
  margin-bottom: 20px;
}
.recipe-single-wrapper .recipe-categories .recipe-category {
  margin-right: 5px;
  color: #ffffff;
  background-color: var(--color-main);
  padding: 3px 7px;
  border-radius: var(--border-radius-small);
  font-size: var(--fontsize-small);
}
.recipe-single-wrapper .recipe-images {
  display: block;
  margin-bottom: 20px;
}
.recipe-single-wrapper .recipe-single-image-wrapper {
  position: relative;
  padding-bottom: 70%;
}
.recipe-single-wrapper .recipe-single-image {
  background-position: center center;
  background-size: cover;
  position: relative;
  border-radius: var(--border-radius-regular);
  flex-shrink: 0;
  margin-bottom: 20px;
  background-color: var(--color-grey-border);

  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
}
.recipe-single-wrapper .recipe-single-time {
  display: flex;
  margin-bottom: 20px;
  background-color: var(--color-main);
  color: #ffffff;
  justify-content: space-around;
  padding: 15px 20px;
  border-radius: var(--border-radius-regular);
  flex-wrap: wrap;
  gap: 15px;
}
.recipe-single-wrapper .recipe-single-time div {
  display: flex;
  align-items: center;
}
.recipe-single-wrapper .recipe-single-time svg {
  margin-right: 5px;
}
.recipe-single-wrapper .recipe-add-to-fav,
.recipe-single-wrapper .recipe-mark-as-done,
.recipe-single-wrapper .recipe-add-to-ration,
.recipe-single-wrapper .recipe-print,
.recipe-single-wrapper .recipe-add-to-fav:focus,
.recipe-single-wrapper .recipe-add-to-fav:active,
.recipe-single-wrapper .recipe-mark-as-done:focus,
.recipe-single-wrapper .recipe-mark-as-done:active {
  cursor: pointer;
  color: var(--color-grey);
}
.recipe-single-wrapper .recipe-add-to-fav {
  margin-left: 10px;
}
.recipe-single-wrapper .recipe-add-to-fav .fav {
  color: var(--color-main);
}
.recipe-single-wrapper .recipe-mark-as-done .done {
  color: var(--color-main);
}
.recipe-single-wrapper .recipe-mark-as-done:hover,
.recipe-single-wrapper .recipe-add-to-fav:hover,
.recipe-single-wrapper .recipe-add-to-ration:hover,
.recipe-single-wrapper .recipe-print:hover {
  color: var(--color-main);
}
.recipe-single-wrapper .recipe-single-ingredients {
  margin-bottom: 30px;
}
.recipe-single-wrapper .recipe-single-ingredients h3 {
  display: flex;
  align-items: center;
}
.recipe-single-wrapper .recipe-single-ingredients h3 span {
  flex-grow: 1;
}
.recipe-single-wrapper .recipe-single-steps {
  margin-bottom: 30px;
}
.recipe-single-wrapper .recipe-single-details {
  display: flex;
  gap: 15px;
  margin-bottom: 30px;
  flex-wrap: wrap;
}
.recipe-single-wrapper .recipe-single-notes,
.recipe-single-wrapper .recipe-single-serving {
  background-color: var(--color-brand-beige);
  border-radius: var(--border-radius-regular);
  padding: 20px;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
}
.recipe-single-wrapper .recipe-single-serving {
  background-color: var(--color-grey-border);
}
@media (max-width: 568px) {
  .recipe-single-wrapper .recipe-single-notes,
  .recipe-single-wrapper .recipe-single-serving {
    min-width: 100%;
    flex-basis: auto;
  }
}

.recipe-single-wrapper .step-item-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  gap: 15px;
  cursor: pointer;
}
.recipe-single-wrapper .step-item-wrapper:hover .step-item-index {
  background-color: var(--color-black);
}
.recipe-single-wrapper .user-notes {
  width: 100%;
  height: auto;
  overflow-y: hidden;
  background: transparent;
  font-style: italic;
  font-size: 0.95rem;
  margin-bottom: 15px;
  border-width: 0px;
  border-style: dashed;
  border-radius: 10px;
  margin-bottom: 0;
}
.recipe-single-wrapper .user-notes-title {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
}
.recipe-single-wrapper .user-notes-title h4 {
  flex-grow: 1;
  margin-bottom: 0;
}
.recipe-single-wrapper .user-notes-title button {
  flex-grow: 0;
  width: auto;
  font-size: 0.8rem;
  padding: 5px 10px;
}
.recipe-single-wrapper .user-notes:focus {
  border-width: 1px;
  border-color: #ccc;
}
.step-item-index {
  color: #ffffff;
  background-color: var(--color-main);
  width: 32px;
  height: 32px;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.step-item-wrapper.step-item-wrapper-done .step-item-text {
  color: var(--color-main);
}
.recipe-single-wrapper .step-item-wrapper.done {
  opacity: 0.5;
}
.tags-items-wrapper {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.tag-item-wrapper {
  background-color: var(--color-grey-border);
  padding: 5px 7px;
  border-radius: var(--border-radius-small);
  text-overflow: ellipsis;
  overflow: hidden;
}
/*
* Ingredients list
*/
.ingredient-item-wrapper {
  display: flex;
  align-items: center;
  gap: 7px;
  margin-bottom: 3px;
  padding-bottom: 3px;
  border-bottom: 1px dashed var(--color-grey-border);
  cursor: pointer;
}
.ingredient-item-wrapper.ingredient-section {
  margin-bottom: 10px;
  margin-top: 15px;
  border-bottom: 0;
}

.ingredient-item-wrapper.ingredient-section .ingredient-title {
  font-weight: bold;
  cursor: default;
}
.ingredient-item-wrapper.ingredient-section .ingredient-title:hover {
  color: var(--color-black);
}
.ingredient-item-wrapper:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}
.ingredient-item-wrapper svg {
  position: relative;
  top: 1px;
}
.ingredient-add-to-shoplist {
  flex-shrink: 0;
  color: var(--color-grey);
}
.ingredient-item-wrapper:hover {
  color: var(--color-main);
}
.ingredient-item-wrapper:hover .ingredient-add-to-shoplist {
  color: var(--color-main);
}
@media (max-width: 568px) {
  .ingredient-add-to-shoplist svg {
    font-size: 1.6rem;
  }
}

/* Recipe Links */
.recipe-links-wrapper {
  margin-top: 15px;
  background-color: var(--color-grey-border);
  border-radius: var(--border-radius-regular);
  padding: 15px;
}
.recipe-links-title {
  cursor: pointer;
  color: var(--color-main);
  display: inline-block;
}
.recipe-links-title svg {
  font-size: 1.2rem;
  position: relative;
  top: 3px;
}
.recipe-links-list img {
  width: 16px;
  height: 16px;
  margin-right: 7px;
  display: inline-block;
  position: relative;
  top: 2px;
}
.recipe-links-title:hover {
  color: var(--color-black);
}
.recipe-links-list {
  margin-top: 10px;
}
.recipe-links-list a {
  display: block;
  margin-bottom: 5px;
}

/* Account page */
.page-section-details-wrapper {
  margin-bottom: 30px;
}
.page-section-details-row {
  display: flex;
  margin-bottom: 15px;
}
.page-section-details-row .page-section-details-label {
  font-weight: bold;
  width: 240px;
  color: var(--color-main);
  flex-shrink: 0;
}
.page-section-details-row .active {
  color: var(--color-brand-green);
}
.page-section-details-row .not-active {
  color: var(--color-brand-red);
}

/*
* Shoplist page
*/
.page-shoplist .page-title-wrapper {
  display: flex;
  align-items: center;
}
.page-shoplist .page-title-wrapper h1 {
  flex-grow: 1;
}
/*
* Ration page
*/
.ration-form-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ration-form-wrapper form {
  max-width: 480px;
  flex-grow: 1;
}
.ration-form-wrapper {
  background-color: var(--color-lightgrey);
  padding: 20px 15px;
  margin-left: -15px;
  margin-right: -15px;
}
.ration-form-wrapper .buttons-wrapper {
  margin-bottom: 0;
}
.ration-item-list {
  margin-top: 15px;
}
.ration-item {
  margin-left: -15px;
  margin-right: -15px;
}
.ration-item a {
  display: flex;
  padding: 10px 15px;
  border-bottom: 1px solid var(--color-grey-border);
  align-items: center;
  color: var(--color-main);
}
.ration-item .ration-item-title {
  flex-grow: 1;
}
.ration-item .ration-item-icon {
  flex-shrink: 0;
  font-size: 2rem;
  height: 2rem;
  color: var(--color-grey);
}
.ration-item .ration-item-list-icon {
  flex-shrink: 0;
  font-size: 1.6rem;
  height: 2rem;
  color: var(--color-grey);
  margin-right: 10px;
}
.ration-item a:hover .ration-item-title {
  color: var(--color-black);
}
.ration-item a:hover .ration-item-icon,
.ration-item a:hover .ration-item-list-icon {
  color: var(--color-main);
}
.ration-item .ration-item-date {
  color: var(--color-grey);
  font-size: 0.8rem;
}

.page-ration .page-title-wrapper {
  display: flex;
  align-items: center;
}
.page-ration .page-title-wrapper h1 {
  flex-grow: 1;
}
.page-ration .ration-date {
  font-family: 'Ubuntu';
  font-size: 1rem;
  font-weight: normal;
  color: var(--color-grey);
  margin-top: 5px;
}
.ration-day-wrapper {
  margin-bottom: 15px;
}
.ration-day-wrapper .ration-day-title {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ration-day-wrapper .ration-day-title h3 {
  margin-top: 0;
  margin-bottom: 0;
  flex-grow: 1;
  color: var(--color-black);
}
.ration-day-wrapper .ration-day-title h3 span {
  color: var(--color-brand-green);
}
.ration-day-wrapper .ration-day-title.ration-day-title-today h3 {
  color: var(--color-main);
}
.ration-day-wrapper .ration-day-switch {
  flex-shrink: 0;
  flex-grow: 0;
  font-size: 2rem;
  color: var(--color-grey);
  margin-right: 5px;
  height: 2rem;
  margin-left: -7px;
}
.ration-day-wrapper:hover .ration-day-title h3,
.ration-day-wrapper:hover .ration-day-switch {
  color: var(--color-main);
}
.ration-day-wrapper .recipe-card.recipe-card-line:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

/* WW Payment page */
.page-ww-payment ul li {
  margin-bottom: 5px;
}

/* Ingredients */
.blog-link {
  font-weight: normal;
  font-size: 1rem;
  display: flex;
  align-items: center;
  font-family: 'Ubuntu';
  border-bottom: 1px dotted var(--color-grey);
  flex-grow: 0;
}
.blog-link span {
  margin-left: 5px;
}

/* Blog post */
.blog-posts {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}
.blog-posts .post-card {
  flex-basis: 50%;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 30px;
}
@media (max-width: 568px) {
  .blog-posts .post-card {
    flex-basis: 100%;
  }
}
.blog-posts .post-card .post-image {
  margin-bottom: 20px;
  position: relative;
}
.blog-posts .post-card .post-category {
  margin-right: 5px;
  color: #ffffff;
  background-color: var(--color-main);
  padding: 3px 7px;
  border-radius: var(--border-radius-small);
  font-size: var(--fontsize-small);
  position: absolute;
  top: 20px;
  left: 20px;
}
.blog-posts .post-card .post-image img {
  width: 100%;
  height: auto;
  border-radius: var(--border-radius-regular);
}
.blog-posts .post-card .post-title {
  margin-bottom: 10px;
}
.blog-posts .post-card .post-date {
  color: var(--color-grey);
  margin-bottom: 20px;
}
.blog-posts .post-card h2 {
  margin: 0;
}
/* Loader */
.loader-margin {
  margin-left: 15px;
  margin-right: 15px;
}
.loader-center {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
/* Tags slider */
.section-tags-slider .swiper {
  height: 30px;
}
/* Recipe page slider */
@media (min-width: 768px) {
  .page-recipe .one-image .swiper-wrapper {
    justify-content: center;
  }
}

/* Print */
@media print {
  .recipe-images,
  .recipe-links-wrapper {
    display: none;
  }
}
